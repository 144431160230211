<template>
  <div class="body container">
    <el-alert>
      Loading...
    </el-alert>
  </div>
</template>

<script>
export default {
  async mounted() {
    window.location.href = "https://page.line.me/ivyway";
  }
};
</script>

<style scoped>
.body {
  margin-top: 20px;
}
</style>
